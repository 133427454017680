.main-navbar {
  .fixed-main-navbar{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
  }

  #withoutScroll .navbarbrand-title {
    color: #FFFFFF;
    font-family: "Myriad Pro";
    font-size: 15px;
    letter-spacing: .8px;
    font-weight: 500;
    line-height: 15px;
    transition: 0.3s;
  }
  #withoutScroll .navbarbrand-text {
    color: #FFFFFF;
    font-family: "Myriad Pro Bold";
    font-size: 15px;
    letter-spacing: 1.4px;
    line-height: 16px;
    transition: 0.3s;
  }
  #withoutScroll.navbar {
    height: 72px !important;
    width: 100% !important;
    background-color: #E10210 !important;
    z-index: 2000;
    padding-bottom: 0;
    padding-top:0;
  }
  .navbar-brand {
    padding-top: .3rem;
    padding-bottom: 0;
    margin-right: 0;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #fff !important;
    display: inline-block;
    padding-left: 5px;
    vertical-align: middle;
    letter-spacing: 1px;
    line-height: 13px;
    font-size: 14px;
    //padding-top:1.3rem;
    span {
      font-weight: 700!important;
      letter-spacing: 1.6px!important;
      margin-left: 1px!important;
    }
  }

  #withoutScroll.input-group > .form-control {
    box-sizing: border-box;
    height: 49px;
    width: auto;
    border: 1px solid #DBE5EE;
    border-left: none;
    //border-radius: 4px;
    border-top-left-radius: 0;
  	border-top-right-radius: 4px;
  	border-bottom-right-radius: 4px;
  	border-bottom-left-radius: 0;
    background-color: #FAFBFE;
    box-shadow: none;
  }
  #withoutScroll.danfoss-logo {
    width: 144px;
    height: auto;
      transition: 0.2s;
  }
  #withoutScroll.engineering-img {
    width: 100px;
    margin-left: -5px;
    margin-right: 76px;
  }
}
.hamburger-btn {
  position: fixed;
	top:15px;
	right:20px;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 0;
  margin: 0 5px 0 5px;
  box-shadow: none;
  img {
    height: 20px;
    width: auto;
  }
}
.submenu-navbar {
  a:hover {
    color: #b6000f;
  }
  a {
    color: #303030;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #303030;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    color: #b6000f!important;
  }
  #withoutScroll.navbar {
    height: 48px !important;
    width: 100% !important;
    background-color: #fff !important;
    box-shadow: 0 1px 4px rgba(0,0,0,.25) !important;
    z-index: 2000;
    .nav-item {
      height: 48px !important;
      .dropdown {
        height: 48px;
        background-color: transparent;
      }
      .dropdown-menu {
        padding: 10px 0 10px 10px;
      }
      .dropdown-item {
        padding: 0 0 0 0;
        background-color: transparent;
        color: #4f4f4f;
        border-radius: 0;
        &:hover {
          background-color: transparent;
        }
      }
      .btn-secondary {
        text-transform: initial;
        height: 48px;
        padding: 0;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .navbar-brand {
    padding-top: .3rem;
    padding-bottom: .3rem;
    margin-right: 0;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }
  #withoutScroll a {
    display: inline-block;
    padding: 10px 10px 10px 10px ;
    vertical-align: middle;
    margin-right: 20px;
    letter-spacing: 1px;
    line-height: 28px;
    font-size: 18px;
    font-weight: 600;
    .drop {
      padding: 0 0 0 0 !important;
    }
  }

  .nav-link{
    &:after {
      border-top: none;
      border-right: none;
      border-bottom: none;
      border-left: none;
    }
    &:active {
      &:after {
        border-top: none;
        border-right: none;
        border-bottom: none;
        border-left: none;
      }
    }
  }
  .dropdown-logo {
    width: 15px;
    height: auto;
  }
  #withoutScroll.active-link {
    content: "";
    height: 3px;
    width: calc(100% - 30px);
    background-color: #E10210;
    display: block;
    margin: 0 0 2px;
    border-radius: 2px;
    position: relative;
    bottom: 6px;
    left: 6px;
  }
  #withoutScroll.active-link-drop {
    content: "";
    height: 3px;
    width: calc(100% - 30px);
    background-color: #E10210;
    display: block;
    margin: 0 0 2px;
    border-radius: 2px;
    position: relative;
    bottom: 11px;
    left: 6px;
    z-index: -1;
  }

  .minus-margin {
    margin-bottom: -4px;
  }
  #product.modal{
      //pointer-events: inherit!important;
      top: 107px;
      left: -13.7%;
  }
  #product .modal-dialog {
    margin-top: 14px !important;
  }
  #productWithScroll.modal{
      //pointer-events: inherit!important;
      top: 80px;
      left: -13.7%;
  }
  #productWithScroll .modal-dialog {
    margin-top: 14px !important;
  }
  .link-products {
    padding: 0 45px 10px 15px!important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 400 !important;

  }
  .link-categories {
    padding: 0 45px 10px 15px!important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 700 !important;

  }
  .link-categories:focus-visible {
    border: none!important;
    outline: none!important;
  }
}

.link-navbar {
  a:hover {
    color: #b6000f;
  }
  .navbar-light .navbar-nav a:hover {
    color: #b6000f;
  }
  .navbar-light .navbar-nav a {
    color: #303030;
    font-family: "Myriad Pro";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
  }
  .navbar {
    box-shadow: none;
    //background-color: #ffffff!important;
    //opacity: 0.4 !important;
    padding-bottom: 0;
  }
  .bg-secondary {
    background-color: #ebebeb !important;
  }
  .nav-item {
    padding-right: 15px;
  }

  #navbarcolumn.navbar-nav  {
    flex-direction: column !important;
  }
  #navbarrow.navbar-nav  {
    flex-direction: row !important;
  }
  .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 0;
  }
  #withoutScroll.nav-item {
    padding-bottom: 15px;
  }
  #withoutScroll.title-text{
    color: #303030;
    font-family: "Myriad Pro Bold";
    font-size: 28px;
    letter-spacing: 0;
    line-height: 34px;
  }
}

.side-navbar {
  position: fixed;
  top: 0;
  z-index: 3000;
  right:0;
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: "Myriad Pro";
    font-size: 18px;
    padding: 1rem 1.5rem;
    height: 3rem;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 5px;
    transition: all .3s linear;
    span {
      font-size: 22px;
      font-family: "Myriad Pro Bold";

    }
    .dropdown-text {
      font-size: 14px !important;
      font-family: "Myriad Pro";
    }

  }

}

.productpageNavbar {
  .navbar {
    padding: 0;
    box-sizing: border-box;
    height: 49px;
    width: 100%;
    border: 1px solid #D8D8D9;
    background-color: #F4F4F4!important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  }
  .navbar-light .navbar-nav .nav-item {
      height: 49px;
      padding: 0 15px 0 15px;
  }
  .navbar-light .navbar-nav .nav-link {
      height: 49px;
      padding: 0;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    color:#b6000f;
  }
  .navbar-light .navbar-nav .nav-link.active {
      color: #b6000f!important;
      height: 43px!important;
  }
  .navbar-light .navbar-nav .nav-item.active {
      background-color: #fff;
      border-top: solid 3px #b6000f;
      height: 46px;
  }
}

.wrapper {
  column-count: 1;
  column-gap: 100%;
  padding: 30px 0 20px 0;
  p {
    text-align: justify;
  }
  .title-text {
    color: #303030;
    font-family: "Myriad Pro Bold";
    font-size: 28px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .detail-text {
    color: #686868;
    font-family: "Myriad Pro";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .star {
    float: left;
    shape-margin:20px;
    margin-right: 20px;
    margin-bottom: 10px;
    .product-img-small {
  			height: auto;
  		  width: 100px;
  		  border-radius: 5px;
  	}
    .product-img-small-border {
  			height: auto;
  		  width: 100px;
  		  border-radius: 5px;
        border: 2px solid #303030;
  	}
    .product-img-big {
      height: auto;
      box-sizing: border-box;
      width: 517px;
      border: 1px solid #D8D8D9;
    }
    .navbar {
      box-shadow: none;
      padding-top: 0;
      width: 100px;
      .nav-item {
  			.nav-link {
          box-sizing: border-box;
          border-radius: 6px;
  				opacity: 0.3;
  				padding: 0 0 0 0;
  				&.active {
  					opacity: 1;
  				}
  			}
  		}
    }
  }
}

.tooltip-class {
  z-index: 3000;
  position: absolute;
  top: 50px;
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .main-navbar {
    .fixed-main-navbar{
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 2000;
    }
    #withoutScroll .navbarbrand-title {
      color: #FFFFFF;
      font-family: "Myriad Pro";
      font-size: 13px;
      letter-spacing: .8px;
      font-weight: 500;
      line-height: 13px;
    }
    #withoutScroll .navbarbrand-text {
      color: #FFFFFF;
      font-family: "Myriad Pro Bold";
      font-size: 13px;
      letter-spacing: 1.4px;
      line-height: 14px;
    }
    #withoutScroll.navbar {
      height: 52px !important;
      width: 100% !important;
      background-color: #E10210 !important;
      z-index: 2000;
      padding-bottom: 0;
      padding-top:0;
    }
    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
      margin-right: 0;
      font-size: 1.25rem;
      text-decoration: none;
      white-space: nowrap;
    }
    .w-100 {
      width: 40% !important;
    }
    .nav-item {
      display: inline-grid;
    }
    .navbar-light .navbar-nav .nav-link {
      color: #fff !important;

        display: inline-block;
        padding-left: 5px;
        vertical-align: middle;
        letter-spacing: 1px;
        line-height: 13px;
        font-size: 14px;
        //padding-top:1.3rem;
        span {
          font-weight: 700!important;
          letter-spacing: 1.6px!important;
          margin-left: 1px!important;
        }
    }

    #withoutScroll.input-group > .form-control {
      box-sizing: border-box;
      height: 35px;
      width: 350px;
      border: 1px solid #DBE5EE;
      border-top-left-radius: 0;
    	border-top-right-radius: 4px;
    	border-bottom-right-radius: 4px;
    	border-bottom-left-radius: 0;
      background-color: #FAFBFE;
      box-shadow: none;
    }
    #withoutScroll.danfoss-logo {
      width: 95px;
      height: auto;
    }
    #withoutScroll.engineering-img {
      width: 90px;
      margin-left: 5px;
      margin-right: 76px;
    }
  }
  .link-navbar {
    /*.fixed-top {
      top: 50px !important;
    }*/
    .nav-item {
      padding-right: 15px;
    }

    #navbarcolumn.navbar-nav  {
      flex-direction: column !important;
    }
    #navbarrow.navbar-nav  {
      flex-direction: row !important;
      margin-bottom: 0 !important;
    }
    .navbar-nav .nav-link {
      padding-right: 1.5rem;
      padding-left: 0;
    }
    #withoutScroll.nav-item {
      padding-bottom: 0;
    }
    #withoutScroll.title-text{
      color: #303030;
      font-family: "Myriad Pro Bold";
      font-size: 24px;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-navbar {
    .fixed-main-navbar{
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 2000;
    }
    #withoutScroll .navbarbrand-title {
      color: #FFFFFF;
      font-family: "Myriad Pro";
      font-size: 13px;
      letter-spacing: .8px;
      font-weight: 500;
      line-height: 13px;
    }
    #withoutScroll .navbarbrand-text {
      color: #FFFFFF;
      font-family: "Myriad Pro Bold";
      font-size: 13px;
      letter-spacing: 1.4px;
      line-height: 14px;
    }
    #withoutScroll.navbar {
      height: 52px !important;
      width: 100% !important;
      background-color: #E10210 !important;
      z-index: 2000;
      padding-bottom: 0;
      padding-top:0;
    }
    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
      margin-right: 0;
      font-size: 1.25rem;
      text-decoration: none;
      white-space: nowrap;
    }
    .w-100 {
      width: 40% !important;
    }
    .nav-item {
      display: inline-grid;
    }
    .navbar-light .navbar-nav .nav-link {
      color: #fff !important;

        display: inline-block;
        padding-left: 5px;
        vertical-align: middle;
        letter-spacing: 1px;
        line-height: 13px;
        font-size: 14px;
        //padding-top:1.3rem;
        span {
          font-weight: 700!important;
          letter-spacing: 1.6px!important;
          margin-left: 1px!important;
        }
    }

    #withoutScroll.input-group > .form-control {
      box-sizing: border-box;
      height: 35px;
      width: 350px;
      border: 1px solid #DBE5EE;
      border-top-left-radius: 0;
    	border-top-right-radius: 4px;
    	border-bottom-right-radius: 4px;
    	border-bottom-left-radius: 0;
      background-color: #FAFBFE;
      box-shadow: none;
    }
    #withoutScroll.danfoss-logo {
      width: 95px;
      height: auto;
    }
    #withoutScroll.engineering-img {
      width: 90px;
      margin-left: 5px;
      margin-right: 76px;
    }
  }
  .container {
    max-width: 95%;
  }
  .link-navbar {
    .fixed-top {
      top: 50px !important;
    }
    .nav-item {
      padding-right: 15px;
    }

    #navbarcolumn.navbar-nav  {
      flex-direction: column !important;
    }
    #navbarrow.navbar-nav  {
      flex-direction: row !important;
      margin-bottom: 0 !important;
    }
    .navbar-nav .nav-link {
      padding-right: 1.5rem;
      padding-left: 0;
    }
    #withoutScroll.nav-item {
      padding-bottom: 0;
    }
    #withoutScroll.title-text{
      color: #303030;
      font-family: "Myriad Pro Bold";
      font-size: 24px;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
  .hamburger-btn {
    img {
      height: 30px!important;
      width: auto!important;
    }
  }
  .container {
    max-width: 95%;
  }
  .w-100 {
    width: auto !important;
  }
  .wrapper {
    padding: 50px 0 10px 0;
    .detail-text {
      font-size: 14px;
    }
    .star {
      .product-img-big {
        height: auto;
        box-sizing: border-box;
        width: 350px;
        border: 1px solid #D8D8D9;
      }
    }
  }
  .link-navbar {
    #withoutScroll.fixed-top {
      top: 70px !important;
    }
    .nav-item {
      padding-right: 15px;
    }

    #navbarcolumn.navbar-nav  {
      flex-direction: column !important;
    }
    #navbarrow.navbar-nav  {
      flex-direction: row !important;
      margin-bottom: 0 !important;
    }
    .navbar-nav .nav-link {
      padding-right: 1.5rem;
      padding-left: 0;
    }
    #withoutScroll.nav-item {
      padding-bottom: 0;
    }
    #withoutScroll.title-text{
      color: #303030;
      font-family: "Myriad Pro Bold";
      font-size: 24px;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .submenu-navbar {
    #withoutScroll.navbar {
      height: 38px !important;
    }
    #withoutScroll a {
      padding: 10px 45px 10px 0 !important;
      letter-spacing: 1px;
      line-height: 28px;
      font-size: 16px;
    }
    #withoutScroll.active-link {
      height: 3px;
      width: calc(100% - 45px);
      margin: 0 0 2px;
      position: relative;
      bottom: 6px;
      left: 0;
    }
    #withoutScroll.active-link-drop {
      height: 3px;
      width: calc(100% - 45px);
      margin: 0 0 2px;
      border-radius: 2px;
      bottom: 11px;
      left: 0;
    }
    #product.modal{
        top: 98px;
        left: 0;
    }
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) {  }
