@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;800&display=swap');

html {
	height: 100vh;
}

body {
	min-height: 100vh;
	font-size: 14px;
	font-family: 'Myriad Pro';
	font-weight: 500 !important;
	font-stretch: condensed;


}

@font-face {
  font-family: 'Myriad Pro Bold';
  src: url('../media/fonts/MYRIADPRO-BOLD.woff') format('truetype');
  font-weight: 900;
  font-stretch: condensed;
}
/*@font-face {
  font-family: 'Myriad Pro';
  src: url('../media/fonts/MYRIADPRO-BOLDCOND.woff') format('truetype');
  font-weight: 700;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../media/fonts/MYRIADPRO-BOLDCONDIT.woff') format('truetype');
  font-weight: 500;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../media/fonts/MYRIADPRO-BOLDIT.woff') format('truetype');
  font-weight: 300;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../media/fonts/MYRIADPRO-COND.woff') format('truetype');
  font-weight: 300;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../media/fonts/MYRIADPRO-CONDIT.woff') format('truetype');
  font-weight: 300;
  font-stretch: condensed;
}*/
/*@font-face {
  font-family: 'Myriad Pro';
  src: url('../media/fonts/MyriadPro-Light.woff') format('truetype');
  font-weight: 300;
  font-stretch: condensed;
}*/
@font-face {
  font-family: 'Myriad Pro';
  src: url('../media/fonts/MYRIADPRO-REGULAR.woff') format('truetype');
  font-weight: 300;
  font-stretch: condensed;
}
/*@font-face {
  font-family: 'Myriad Pro';
  src: url('../media/fonts/MYRIADPRO-SEMIBOLD.woff') format('truetype');
  font-weight: 300;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../media/fonts/MYRIADPRO-SEMIBOLDIT.woff') format('truetype');
  font-weight: 300;
  font-stretch: condensed;
}*/

//pages
@import "pages/navbarpage.scss";
@import "pages/footerpage.scss";
@import "pages/landingpage.scss";
@import "pages/legalpages.scss";
@import "pages/adminlandingpage.scss";
@import "pages/adminproductspage.scss";
@import "pages/modal.scss";
@import "pages/userservicemodal.scss";
@import "pages/homepage.scss";
@import "pages/softwarepage.scss";



.FileInput_input {
  display: none;
}

.software-detail-text {
	color: #686868;
	font-family: "Myriad Pro";
	font-size: 16px;
	letter-spacing: 0;
	line-height: 24px;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
  background-color: #E10210;
  color: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
	border: none;
	border-right: none;
	//border-radius: 4px;
}

.download-btn {
	width: auto;
	height: 38px;
	align-items: center;
	justify-content: center;
	display: flex;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 0 15px 0 15px;
	background-color: #E10210 !important;
	text-transform: initial;
	color: #FFFFFF !important;
	font-size: 16px !important;
	font-family: "Myriad Pro Bold" !important;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
}

.transparent-fixed{
  //position: absolute;
  //left: 30px;
  background-color: transparent!important;
  box-shadow:none!important;
  color: #686868;
  padding: 5px;
  margin: 0 10px 0 0;
  &:hover {
    color: #686868!important;
    box-shadow:none!important;
    background-color: transparent!important;
  }
  &:active {
    color: #686868!important;
    box-shadow:none!important;
    background-color: transparent!important;
  }
  &:focus {
    color: #686868!important;
    box-shadow:none!important;
    background-color: transparent!important;
  }

}

.btn-primary {
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}

.logout-btn {
	background-color: transparent !important;
	box-shadow: none !important;
	span {
		text-transform: initial;
		font-family: "Myriad Pro Bold";
		font-size: 16px;
		letter-spacing: 0;
		text-align: center;
	}
	img {
		width: 55px;
	}
}

.software-btn {
	background-color: transparent !important;
	box-shadow: none !important;
	padding: 0 10px 8px 10px;
	span {
		text-transform: initial;
		font-family: "Myriad Pro Bold";
		font-size: 16px;
		letter-spacing: 0;
		text-align: center;
	}
	img {
		width: 25px;
	}
}

.spinner-position {
  position: absolute;
  top: 55px;
  left: 58%;
  transform: translate(-50%,-50%);
  .preloader-wrapper {
    width: 2rem;
    height: 2rem;
    .spinner-blue-only{
      border-color: #FF9500!important;
    }
  }

}

.userservice-btn {
	background-color: transparent !important;
	box-shadow: none !important;
	span {
		text-transform: initial;
		font-family: "Myriad Pro Bold";
		font-size: 16px;
		letter-spacing: 0;
		text-align: center;
	}
	img {
		width: 70px;
	}
}

.danfoss-btn {
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
	border-radius: 4px;
	background-color: #E10210;
	text-transform: initial;
	color: #FFFFFF;
	font-size: 17px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
	.admin {
		height: 30px;
		width: 159.65px;
		font-size: 14px!important;
		line-height: 16px!important;
	}
	span {
		padding-left:5px;
	}
}
.newpage-btn {
	height: 30px;
  width: 104.77px;
  background-color: #E10210!important;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2)!important;
	padding: 0;
	margin: 0;
	border-radius: 1px;
	span {
		//height: 16px;
	  //width: 105px;
	  color: #FFFFFF;
	  font-family: "Myriad Pro";
	  font-size: 14px;
	  letter-spacing: 0;
	  line-height: 16px;
	  text-align: center;
		text-transform: initial;
	}
}


#withoutScroll.search-button {
  background-color: #FAFBFE !important;
	padding: .625rem .7rem .625rem 1rem !important;
	border: 1px solid #DBE5EE;
	border-right: none;
	border-top-left-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 4px;
	box-shadow: none;
	transition: 0.3s;
}

#scroll.search-button {
	padding: .3rem .7rem .3rem 1rem !important;
  background-color: #FAFBFE !important;
	border: 1px solid #DBE5EE;
	border-right: none;
	border-top-left-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 4px;
	box-shadow: none;
	transition: 0.3s;
}

.keep-me {
	height: 48px;
	width: 297px;
}

.ImageInput_input {
	display: none;
}


.ck.ck-content ul li{
	list-style: none;
  padding: 3px 30px 3px 30px;
  background-image: url('../media/icons/enumeration.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
}

#bullet-point {
	ul {
		padding-left: 0;
	}
	ul li{
		list-style: none;
	  padding: 3px 30px 3px 32px;
	  background-image: url('../media/icons/enumeration.svg');
	  background-repeat: no-repeat;
	  background-position: left center;
	  background-size: 21px;
		color: #686868;
	  font-family: "Myriad Pro";
	  font-size: 16px;
	  letter-spacing: 0.13px;
	  line-height: 24px;
	}
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 30px;
}

.deletefunction-btn {
	background-color: #fff !important;
	box-shadow: none !important;
	border-radius: 50%;
	width:40px;
	height:37px;
	padding: 0;
	margin: 0;
	img {
		height: 22px;
		width: 15px;
	}
}
.change-image-btn {
	padding: 0;
	margin: 0;
  height: 30px;
  width: 112.76px;
  border: 1px solid #E10210;
  border-radius: 1px;
  background-color: #F4F4F4 !important;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
	color: #686868;
  font-size: 14px;
  text-align: center;
	text-transform: initial;
	&:hover {
		border: 1px solid #E10210;
	  border-radius: 1px;
	  background-color: #F4F4F4 !important;
	  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
		color: #686868;
	}
}

.sorted-button {
	padding: 0;
	margin: 0;
	background-color: transparent!important;
	box-shadow: none!important;
	&:hover {
		background-color: transparent!important;
		box-shadow: none!important;
	}
	img {
		width: 18px;
	}
}

.select-section-btn {
	padding: 0;
	margin: 0;
	height: 107px;
	width: 161px;
	border: 1px solid #E10210;
	background-color: #FFFFFF !important;
  box-shadow: none;

	&:hover {
		border: 1px solid #E10210;
	  border-radius: 1px;
	  background-color: #F4F4F4 !important;
	  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
		color: #686868;
	}
	img {
		height: 36px;
		margin-bottom: 15px;
		margin-top: 10px;
	}
	span {
		height: 26px;
	  width: 161px;
	  color: #686868;
	  font-family: "Myriad Pro";
	  font-size: 11px;
	  letter-spacing: 0;
	  //line-height: 16px;
	  text-align: center;
		text-transform: initial;
	}
}

.form-control-undefined {
	width: 161px;
	height: 107px;
	position: absolute;
	opacity: 0;
}

#deleteCategory .select-input.focused ~ .select-arrow {
  color: #4b4d51 !important;
	top: 5px !important;
}
#deleteCategory .select-arrow {
  color: #4b4d51 !important;
	top: 5px !important;
}
.lightbox-gallery {
  z-index: 2100;
}

.scroll-margin {
	scroll-margin-top: 256px;
}

.scroll-margin-withscroll {
	scroll-margin-top: 205px;
}

div.info-tooltip {
	display: inline-block;
	.btn-primary {
	  background-color: transparent !important;
	  padding: 0;
		margin: 0;
	  box-shadow: none !important;
	}


}
div.tooltip.show.fade.bs-tooltip-right {
		inset: -10px auto auto 5px!important;
}
.documentSection-dropdown {
	.select-arrow::before {
	  content: url("../media/icons/Sort_by.svg");
	}
	.form-outline .form-control ~ .form-notch div {
		border:none;
	}
	.select-input.form-control[readonly]:not([disabled]) {
	  visibility: hidden;
		width: 35px;
	}
	.select-input.focused ~ .form-notch {
		.form-notch-leading {
		  border: none;
		  box-shadow: none;
		}
		.form-notch-middle {
		  border: none;
		  box-shadow: none;
		}
		.form-notch-trailing {
			border: none;
			box-shadow: none;
		}
	}

}

.select-dropdown {
	min-width: 150px;
}

.more-text::after {
	background-image: url('../media/logo/arrowDown.png');
  background-size: 15px 9px;
  display: inline-block;
  width: 15px;
  height: 9px;
  content:"";
	margin-left: 5px;
}

.more-text {
	&:hover{
		&:after{
			background-image: url('../media/icons/Arrow_Down_red.svg');
		}
	}
}

.modal {
	pointer-events: none!important;
}

.side-navbar {
	.btn {
		pointer-events: auto!important;
	}
}


.where-to-buy-btn {
	position: absolute;
	right: 18%;
	top: 25%;
	.btn-primary {
		width: 138px;
		height: 38px;
		align-items: center;
		justify-content: center;
		display: flex;
		box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
		border-radius: 4px;
		padding: 0;
		background-color: #E10210!important;
		text-transform: initial;
		color: #FFFFFF!important;
		font-size: 16px!important;
		font-family: "Myriad Pro Bold"!important;
		letter-spacing: 0;
		line-height: 20px;
		text-align: center;
	}
}

.datatable-header > tr:nth-child(1) > th:nth-child(1) {
	text-align: left !important;
}

.VideoInput {
	//display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//border: 1px solid #ddd;
}

.VideoInput_input {
	display: none;
}

.VideoInput_video {
	display: block;
	margin: 0;
	border: 1px solid #ddd;
	border-radius: 10px;
}
.VideoInput_video_none {
	display: block;
	height: 0;
	width: 0;
	margin: 0;
	border: none;
}

.select-dropdown-container {
	width: auto!important;
}

@media (max-width: 575.98px) {
	.where-to-buy-btn {
		position: absolute;
		right: 3%;
		top: 45%;
		.btn-primary {
			width: 100px;
			height: 28px;
			font-size: 12px!important;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
	#selectsection .modal-dialog {
		max-width: 730px;
		margin: 1.75rem auto;
	}
	.where-to-buy-btn {
		position: absolute;
		right: 3%;
		top: 45%;
		.btn-primary {
			width: 100px;
			height: 28px;
			font-size: 12px!important;
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {

	.where-to-buy-btn {
		position: absolute;
		right: 3%;
		top: 45%;
		.btn-primary {
			width: 100px;
			height: 28px;
			font-size: 12px!important;
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {

	.where-to-buy-btn {
		position: absolute;
		right: 3%;
		top: 45%;
		.btn-primary {
			width: 100px;
			height: 28px;
			font-size: 12px!important;
		}
	}
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) {

}
@media (min-width: 576px){
	.modal-dialog {
	//max-width: 760px;
	margin: 1.75rem auto;
	}
	#selectsection .modal-dialog {
		max-width: 585px!important;
		margin: 1.75rem auto;
	}
}
